<script setup>
import { useModel } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({ modelValue: Boolean });
// eslint-disable-next-line no-undef
const emit = defineEmits(["update:modelValue"]);

const isDark = useModel(props, "modelValue");
</script>

<template>
  <button class="switcher-wrap" @click="emit('update:modelValue', !isDark)">
    <span class="switcher-circle" :class="{ 'switcher-circle-dark': isDark }">
      &nbsp;
    </span>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2088_1505)">
        <path
          d="M4.71758 3.5004L5.04258 3.8254C5.36758 4.14207 5.36758 4.6754 5.04258 4.99207L5.03424 5.0004C4.70924 5.3254 4.19258 5.3254 3.86758 5.0004L3.54258 4.6754C3.21758 4.35873 3.21758 3.83373 3.54258 3.50873L3.55091 3.5004C3.86758 3.18373 4.39258 3.1754 4.71758 3.5004Z"
          fill="white"
        />
        <path
          d="M1.65898 9.125H2.50898C2.96732 9.125 3.33398 9.49167 3.33398 9.95833V9.96667C3.33398 10.425 2.96732 10.7917 2.50065 10.7833H1.65898C1.20065 10.7833 0.833984 10.4167 0.833984 9.95833V9.95C0.833984 9.49167 1.20065 9.125 1.65898 9.125Z"
          fill="white"
        />
        <path
          d="M9.99935 0.833008H10.0077C10.466 0.833008 10.8327 1.19967 10.8327 1.65801V2.46634C10.8327 2.92467 10.466 3.29134 9.99935 3.28301H9.99102C9.53268 3.28301 9.16602 2.91634 9.16602 2.45801V1.65801C9.16602 1.19967 9.53268 0.833008 9.99935 0.833008Z"
          fill="white"
        />
        <path
          d="M15.2836 3.50014C15.6086 3.18347 16.1336 3.18347 16.4586 3.50847C16.7836 3.83347 16.7836 4.35014 16.4586 4.67514L16.1336 5.00014C15.8169 5.32514 15.2919 5.32514 14.9669 5.00014L14.9586 4.99181C14.6336 4.67514 14.6336 4.15014 14.9586 3.82514L15.2836 3.50014Z"
          fill="white"
        />
        <path
          d="M15.2747 16.4164L14.9497 16.0914C14.6247 15.7664 14.6247 15.2497 14.9581 14.9164C15.2747 14.5997 15.7997 14.5914 16.1247 14.9164L16.4497 15.2414C16.7747 15.5664 16.7747 16.0914 16.4497 16.4164C16.1247 16.7414 15.5997 16.7414 15.2747 16.4164Z"
          fill="white"
        />
        <path
          d="M16.666 9.95833V9.95C16.666 9.49167 17.0327 9.125 17.491 9.125H18.3327C18.791 9.125 19.1577 9.49167 19.1577 9.95V9.95833C19.1577 10.4167 18.791 10.7833 18.3327 10.7833H17.491C17.0327 10.7833 16.666 10.4167 16.666 9.95833Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 9.95801C5 7.19967 7.24167 4.95801 10 4.95801C12.7583 4.95801 15 7.19967 15 9.95801C15 12.7163 12.7583 14.958 10 14.958C7.24167 14.958 5 12.7163 5 9.95801ZM10 13.333C11.8409 13.333 13.3333 11.8406 13.3333 9.99967C13.3333 8.15872 11.8409 6.66634 10 6.66634C8.15905 6.66634 6.66667 8.15872 6.66667 9.99967C6.66667 11.8406 8.15905 13.333 10 13.333Z"
          fill="white"
        />
        <path
          d="M9.99935 19.0838H9.99102C9.53268 19.0838 9.16602 18.7171 9.16602 18.2588V17.4588C9.16602 17.0005 9.53268 16.6338 9.99102 16.6338H9.99935C10.4577 16.6338 10.8243 17.0005 10.8243 17.4588V18.2588C10.8243 18.7171 10.4577 19.0838 9.99935 19.0838Z"
          fill="white"
        />
        <path
          d="M4.71668 16.4088C4.39168 16.7338 3.86668 16.7338 3.54168 16.4088C3.21668 16.0838 3.21668 15.5671 3.53334 15.2338L3.85834 14.9088C4.18334 14.5838 4.70834 14.5838 5.03334 14.9088L5.04168 14.9171C5.35834 15.2338 5.36668 15.7588 5.04168 16.0838L4.71668 16.4088Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2088_1505">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.4608 11.3411L3.46098 11.342C3.97732 14.0097 6.18469 16.1401 8.87213 16.5714L8.8748 16.5718C10.8673 16.895 12.7184 16.3422 14.1181 15.2364C9.46205 13.5735 6.6453 8.62733 7.71851 3.74046C4.74835 4.81028 2.79811 7.92737 3.4608 11.3411ZM8.33988 1.84245C3.97148 2.71141 0.915424 6.97489 1.82468 11.6587C2.47468 15.0171 5.23302 17.6754 8.60805 18.2171C11.5423 18.6929 14.2459 17.6343 16.0548 15.7159C16.0634 15.7068 16.0718 15.6978 16.0803 15.6887C16.1984 15.5624 16.3127 15.4323 16.4229 15.2987C16.425 15.2962 16.4271 15.2936 16.4293 15.2909C16.5707 15.1192 16.7054 14.9416 16.833 14.7587C17.008 14.5004 16.8664 14.1337 16.558 14.1004C16.2591 14.0667 15.9665 14.0177 15.6805 13.9547C15.6633 13.9508 15.6461 13.947 15.629 13.9431C15.556 13.9265 15.4833 13.9089 15.4111 13.8904L15.4065 13.8892C10.8525 12.7209 8.10393 7.90038 9.5448 3.36214C9.54538 3.36045 9.54588 3.35876 9.54646 3.35708C9.57255 3.27495 9.60005 3.1929 9.62896 3.11098C9.63005 3.10792 9.63113 3.10485 9.63222 3.10179C9.7303 2.82467 9.8443 2.5489 9.97472 2.27537C10.108 1.99204 9.87472 1.67537 9.55805 1.69204C9.32913 1.70349 9.10297 1.72406 8.87972 1.7534C8.87605 1.75389 8.8723 1.75438 8.86863 1.75487C8.70072 1.77717 8.53446 1.80443 8.37013 1.8365C8.36005 1.83846 8.34997 1.84045 8.33988 1.84245Z"
        fill="#040707"
      />
    </svg>
  </button>
</template>

<style scoped>
.switcher-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #7a7a7a;
  border-radius: 24px;
  padding: 3px;
  box-sizing: border-box;
  width: 60px;
  height: 32px;
  border: none;
  outline: none;
  cursor: pointer;
}
.switcher-wrap > svg {
  position: relative;
  padding: 3px;
}
.switcher-circle {
  position: absolute;
  background: #000000;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  top: 3px;
  left: 3px;
  transition: all 0.2s ease-in-out;
}
.switcher-circle-dark {
  left: 30px;
  background: rgba(255, 255, 255, 0.9);
}
</style>
