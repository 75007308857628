<script setup>
import { VideoPlayer } from "@videojs-player/vue";

// eslint-disable-next-line no-undef
const props = defineProps({ src: String, id: String });
</script>

<template>
  <video-player
    class="video-player"
    :src="props.src"
    :key="props.src"
    :id="id"
    autoplay
    loop
    muted
    playsinline
  />
</template>

<style>
.video-player {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.video-player :not(video) {
  display: none;
}
.video-player video {
  width: 100%;
  height: 100%;
}
</style>
